<template>
  <div id="adverseEventReport">
    <el-dialog
      :title="adverseEventReportFormTitle"
      width="1200px"
      :visible.sync="adverseEventReportDialogVisible"
      :close-on-click-modal="false"
      @close="adverseEventReportDialogClose"
    >
      <el-form
        ref="adverseEventReportFormRef"
        :model="adverseEventReportForm"
        :rules="adverseEventReportFormRules"
        label-position="right"
        label-width="160px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker v-model="adverseEventReportForm.reportDate" placeholder="请选择报告日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编码" prop="code">
              <el-input v-model="adverseEventReportForm.code" placeholder="请输入编码" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px;">
            <el-form-item label="报告来源" prop="reportSource">
              <el-radio-group v-model="adverseEventReportForm.reportSource">
                <el-radio label="生产企业">
                  生产企业
                </el-radio>
                <el-radio label="经营企业">
                  经营企业
                </el-radio>
                <el-radio label="使用单位">
                  使用单位
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位名称" prop="companyName">
              <el-input v-model="adverseEventReportForm.companyName" placeholder="请输入单位名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系地址" prop="contactAddress">
              <el-input v-model="adverseEventReportForm.contactAddress" placeholder="请输入联系地址" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮编" prop="postcode">
              <el-input v-model="adverseEventReportForm.postcode" placeholder="请输入邮编" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="contactPhone">
              <el-input v-model="adverseEventReportForm.contactPhone" placeholder="请输入联系电话" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="患者姓名" prop="patientName">
              <el-input v-model="adverseEventReportForm.patientName" placeholder="请输入患者姓名" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="患者年龄" prop="patientAge">
              <el-input v-model="adverseEventReportForm.patientAge" placeholder="请输入患者年龄" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="患者性别" prop="patientSex">
              <el-radio-group v-model="adverseEventReportForm.patientSex">
                <el-radio :label="1">
                  男
                </el-radio>
                <el-radio :label="2">
                  女
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预期治疗疾病或作用" prop="diseaseEffect">
              <el-input v-model="adverseEventReportForm.diseaseEffect" placeholder="请输入预期治疗疾病或作用" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="事件主要表现" prop="mainPerformance">
              <el-input v-model="adverseEventReportForm.mainPerformance" placeholder="请输入事件主要表现" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="事件发生日期" prop="incidentDate">
              <el-date-picker v-model="adverseEventReportForm.incidentDate" placeholder="请选择事件发生日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发现或者知悉时间" prop="findKnowDate">
              <el-date-picker v-model="adverseEventReportForm.findKnowDate" placeholder="请选择发现或者知悉时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="医疗器械实际使用场所" prop="actualUsePlace">
              <el-select
                v-model="adverseEventReportForm.actualUsePlace"
                placeholder="请选择医疗器械实际使用场所"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="item in ['医疗机构', '家庭']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="事件后果" prop="aftermath">
              <el-select v-model="adverseEventReportForm.aftermath" placeholder="请选择事件后果" clearable>
                <el-option
                  v-for="item in ['死亡', '危及生命', '机体功能结构永久性损伤', '可能导致机体功能结构永久性损伤', '需要内、外科治疗避免上述永久损伤', '其他（在事件陈述中说明）']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="死亡时间" prop="deathDate">
              <el-date-picker v-model="adverseEventReportForm.deathDate" placeholder="请选择死亡时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="事件陈述" prop="eventStatement">
              <el-input
                v-model="adverseEventReportForm.eventStatement"
                placeholder="请输入事件陈述(至少包括器械使用时间、使用目的、使用依据、使用情况、出现的不良事件情况、对受害者影响、采取的治疗措施、器械联合使用情况)"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="adverseEventReportForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商品名称" prop="commodityName">
              <el-input v-model="adverseEventReportForm.commodityName" placeholder="请输入商品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="注册证号" prop="registrationNo">
              <el-input v-model="adverseEventReportForm.registrationNo" placeholder="请输入注册证号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产企业名称" prop="manufacturingCompanyName">
              <el-input v-model="adverseEventReportForm.manufacturingCompanyName" placeholder="请输入生产企业名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产企业地址" prop="manufacturingCompanyAddress">
              <el-input v-model="adverseEventReportForm.manufacturingCompanyAddress" placeholder="请输入生产企业地址" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业联系电话" prop="companyContactPhone">
              <el-input v-model="adverseEventReportForm.companyContactPhone" placeholder="请输入企业联系电话" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号规格" prop="spec">
              <el-input v-model="adverseEventReportForm.spec" placeholder="请输入型号规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品编号" prop="productNo">
              <el-input v-model="adverseEventReportForm.productNo" placeholder="请输入产品编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品批号" prop="productBatch">
              <el-input v-model="adverseEventReportForm.productBatch" placeholder="请输入产品批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人" prop="operator">
              <el-select
                v-model="adverseEventReportForm.operator"
                placeholder="请选择操作人"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="item in ['专业人员', '非专业人员', '患者']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="有效期" prop="validPeriod">
              <el-input v-model="adverseEventReportForm.validPeriod" placeholder="请输入有效期" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产日期" prop="productionDate">
              <el-date-picker v-model="adverseEventReportForm.productionDate" placeholder="请选择生产日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="停用日期" prop="retirementDate">
              <el-date-picker v-model="adverseEventReportForm.retirementDate" placeholder="请选择停用日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="植入日期" prop="implantDate">
              <el-date-picker v-model="adverseEventReportForm.implantDate" placeholder="请选择植入日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="事件发生初步原因分析" prop="initialCauseAnalysis">
              <el-input v-model="adverseEventReportForm.initialCauseAnalysis" placeholder="请输入事件发生初步原因分析" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="事件初步处理情况" prop="handlingSituation">
              <el-input v-model="adverseEventReportForm.handlingSituation" placeholder="请输入事件初步处理情况" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="事件报告状态" prop="reportStatus">
              <el-select v-model="adverseEventReportForm.reportStatus" placeholder="请选择事件报告状态" clearable>
                <el-option
                  v-for="item in [
                    { value: 1, lable: '已通知使用单位' },
                    { value: 2, lable: '已通知生产企业' },
                    { value: 3, lable: '已通知经营企业' },
                    { value: 4, lable: '已通知药监部门' }
                  ]"
                  :key="item.value"
                  :label="item.lable"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="使用医疗器械与已发生/可能发生的伤害事件之间是否具有合理的先后时间顺序" prop="evaluation1" label-width="680px">
              <el-radio-group v-model="adverseEventReportForm.evaluation1">
                <el-radio label="是">
                  是
                </el-radio>
                <el-radio label="否">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="已发生/可能发生的伤害事件是否属于所使用医疗器械可能导致的伤害类型" prop="evaluation2" label-width="680px">
              <el-radio-group v-model="adverseEventReportForm.evaluation2">
                <el-radio label="是">
                  是
                </el-radio>
                <el-radio label="否">
                  否
                </el-radio>
                <el-radio label="不清楚">
                  不清楚
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="已发生/可能发生的伤害事件是否可用合并用药和/或械的作用、患者病情或其他非医疗器械因素来解释" prop="evaluation3" label-width="680px">
              <el-radio-group v-model="adverseEventReportForm.evaluation3">
                <el-radio label="是">
                  是
                </el-radio>
                <el-radio label="否">
                  否
                </el-radio>
                <el-radio label="不清楚">
                  不清楚
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="评价结论" prop="evaluationConclusion">
              <el-radio-group v-model="adverseEventReportForm.evaluationConclusion">
                <el-radio :label="1">
                  很可能
                </el-radio>
                <el-radio :label="2">
                  可能有关
                </el-radio>
                <el-radio :label="3">
                  可能无关
                </el-radio>
                <el-radio :label="4">
                  无法确定
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="省级监测技术机构评价意见" prop="provincialEvaluationOpinion">
              <el-input
                v-model="adverseEventReportForm.provincialEvaluationOpinion"
                placeholder="请输入省级监测技术机构评价意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国家监测技术机构评价意见" prop="nationalEvaluationOpinion">
              <el-input
                v-model="adverseEventReportForm.nationalEvaluationOpinion"
                placeholder="请输入国家监测技术机构评价意见"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告人" prop="reporter">
              <el-radio-group v-model="adverseEventReportForm.reporter">
                <el-radio :label="1">
                  医师
                </el-radio>
                <el-radio :label="2">
                  技师
                </el-radio>
                <el-radio :label="3">
                  护士
                </el-radio>
                <el-radio :label="4">
                  其他
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告人签名" prop="reporterSignature">
              <el-input v-model="adverseEventReportForm.reporterSignature" placeholder="请输入报告人签名" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="adverseEventReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="adverseEventReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="单位名称">
        <el-input v-model="searchForm.companyName" placeholder="请输入单位名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="adverseEventReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="编码" />
      <el-table-column prop="reportSource" label="报告来源" />
      <el-table-column prop="companyName" label="单位名称" />
      <el-table-column prop="contactAddress" label="联系地址" />
      <el-table-column prop="postcode" label="邮编" />
      <el-table-column prop="contactPhone" label="联系电话" />
      <el-table-column prop="patientName" label="患者姓名" />
      <el-table-column prop="patientAge" label="患者年龄" />
      <el-table-column label="患者性别">
        <template slot-scope="scope">
          <span v-if="scope.row.patientSex === 1">男</span>
          <span v-if="scope.row.patientSex === 2">女</span>
        </template>
      </el-table-column>
      <el-table-column prop="diseaseEffect" label="预期治疗疾病或作用" />
      <el-table-column prop="mainPerformance" label="事件主要表现" />
      <el-table-column label="事件发生日期">
        <template slot-scope="scope">
          <span v-if="scope.row.incidentDate">{{ scope.row.incidentDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发现或者知悉时间">
        <template slot-scope="scope">
          <span v-if="scope.row.findKnowDate">{{ scope.row.findKnowDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="actualUsePlace" label="医疗器械实际使用场所" />
      <el-table-column prop="aftermath" label="事件后果" />
      <el-table-column label="死亡时间">
        <template slot-scope="scope">
          <span v-if="scope.row.deathDate">{{ scope.row.deathDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="eventStatement" label="事件陈述" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="commodityName" label="商品名称" />
      <el-table-column prop="registrationNo" label="注册证号" />
      <el-table-column prop="manufacturingCompanyName" label="生产企业名称" />
      <el-table-column prop="manufacturingCompanyAddress" label="生产企业地址" />
      <el-table-column prop="companyContactPhone" label="企业联系电话" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="productNo" label="产品编号" />
      <el-table-column prop="productBatch" label="产品批号" />
      <el-table-column prop="operator" label="操作人" />
      <el-table-column prop="validPeriod" label="有效期" />
      <el-table-column label="生产日期">
        <template slot-scope="scope">
          <span v-if="scope.row.productionDate">{{ scope.row.productionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="停用日期">
        <template slot-scope="scope">
          <span v-if="scope.row.retirementDate">{{ scope.row.retirementDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="植入日期">
        <template slot-scope="scope">
          <span v-if="scope.row.implantDate">{{ scope.row.implantDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="initialCauseAnalysis" label="事件发生初步原因分析" />
      <el-table-column prop="handlingSituation" label="事件初步处理情况" />
      <el-table-column label="事件报告状态">
        <template slot-scope="scope">
          <span v-if="scope.row.reportStatus === 1">已通知使用单位</span>
          <span v-if="scope.row.reportStatus === 2">已通知生产企业</span>
          <span v-if="scope.row.reportStatus === 3">已通知经营企业</span>
          <span v-if="scope.row.reportStatus === 4">已通知药监部门</span>
        </template>
      </el-table-column>
      <el-table-column prop="evaluation1" label="使用医疗器械与已发生/可能发生的伤害事件之间是否具有合理的先后时间顺序" />
      <el-table-column prop="evaluation2" label="已发生/可能发生的伤害事件是否属于所使用医疗器械可能导致的伤害类型" />
      <el-table-column prop="evaluation3" label="已发生/可能发生的伤害事件是否可用合并用药和/或械的作用、患者病情或其他非医疗器械因素来解释" />
      <el-table-column label="评价结论">
        <template slot-scope="scope">
          <span v-if="scope.row.evaluationConclusion === 1">很可能</span>
          <span v-if="scope.row.evaluationConclusion === 2">可能有关</span>
          <span v-if="scope.row.evaluationConclusion === 3">可能无关</span>
          <span v-if="scope.row.evaluationConclusion === 4">无法确定</span>
        </template>
      </el-table-column>
      <el-table-column prop="provincialEvaluationOpinion" label="省级监测技术机构评价意见" />
      <el-table-column prop="nationalEvaluationOpinion" label="国家监测技术机构评价意见" />
      <el-table-column label="报告人">
        <template slot-scope="scope">
          <span v-if="scope.row.reporter === 1">医师</span>
          <span v-if="scope.row.reporter === 2">技师</span>
          <span v-if="scope.row.reporter === 3">护士</span>
          <span v-if="scope.row.reporter === 4">其他</span>
        </template>
      </el-table-column>
      <el-table-column prop="reporterSignature" label="报告人签名" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="adverseEventReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addAdverseEventReport, deleteAdverseEventReport, updateAdverseEventReport, selectAdverseEventReportInfo, selectAdverseEventReportList } from '@/api/quality/adverseEventReport'

export default {
  data () {
    return {
      adverseEventReportDialogVisible: false,
      adverseEventReportFormTitle: '',
      adverseEventReportForm: {
        id: '',
        reportDate: '',
        code: '',
        reportSource: '',
        companyName: '',
        contactAddress: '',
        postcode: '',
        contactPhone: '',
        patientName: '',
        patientAge: '',
        patientSex: '',
        diseaseEffect: '',
        mainPerformance: '',
        incidentDate: '',
        findKnowDate: '',
        actualUsePlace: '',
        aftermath: '',
        deathDate: '',
        eventStatement: '',
        productName: '',
        commodityName: '',
        registrationNo: '',
        manufacturingCompanyName: '',
        manufacturingCompanyAddress: '',
        companyContactPhone: '',
        spec: '',
        productNo: '',
        productBatch: '',
        operator: '',
        validPeriod: '',
        productionDate: '',
        retirementDate: '',
        implantDate: '',
        initialCauseAnalysis: '',
        handlingSituation: '',
        reportStatus: '',
        evaluation1: '',
        evaluation2: '',
        evaluation3: '',
        evaluationConclusion: '',
        provincialEvaluationOpinion: '',
        nationalEvaluationOpinion: '',
        reporter: '',
        reporterSignature: ''
      },
      adverseEventReportFormRules: {
        companyName: [{ required: true, message: '单位名称不能为空', trigger: ['blur', 'change']}]
      },
      adverseEventReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        companyName: ''
      }
    }
  },
  created () {
    selectAdverseEventReportList(this.searchForm).then(res => {
      this.adverseEventReportPage = res
    })
  },
  methods: {
    adverseEventReportDialogClose () {
      this.$refs.adverseEventReportFormRef.resetFields()
    },
    adverseEventReportFormSubmit () {
      if (this.adverseEventReportFormTitle === '可疑医疗器械不良事件报告详情') {
        this.adverseEventReportDialogVisible = false
        return
      }
      this.$refs.adverseEventReportFormRef.validate(async valid => {
        if (valid) {
          if (this.adverseEventReportFormTitle === '新增可疑医疗器械不良事件报告') {
            await addAdverseEventReport(this.adverseEventReportForm)
          } else if (this.adverseEventReportFormTitle === '修改可疑医疗器械不良事件报告') {
            await updateAdverseEventReport(this.adverseEventReportForm)
          }
          this.adverseEventReportPage = await selectAdverseEventReportList(this.searchForm)
          this.adverseEventReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.adverseEventReportFormTitle = '新增可疑医疗器械不良事件报告'
      this.adverseEventReportDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteAdverseEventReport(row.id)
        if (this.adverseEventReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.adverseEventReportPage = await selectAdverseEventReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.adverseEventReportFormTitle = '修改可疑医疗器械不良事件报告'
      this.adverseEventReportDialogVisible = true
      this.selectAdverseEventReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.adverseEventReportFormTitle = '可疑医疗器械不良事件报告详情'
      this.adverseEventReportDialogVisible = true
      this.selectAdverseEventReportInfoById(row.id)
    },
    selectAdverseEventReportInfoById (id) {
      selectAdverseEventReportInfo(id).then(res => {
        this.adverseEventReportForm.id = res.id
        this.adverseEventReportForm.reportDate = res.reportDate
        this.adverseEventReportForm.code = res.code
        this.adverseEventReportForm.reportSource = res.reportSource
        this.adverseEventReportForm.companyName = res.companyName
        this.adverseEventReportForm.contactAddress = res.contactAddress
        this.adverseEventReportForm.postcode = res.postcode
        this.adverseEventReportForm.contactPhone = res.contactPhone
        this.adverseEventReportForm.patientName = res.patientName
        this.adverseEventReportForm.patientAge = res.patientAge
        this.adverseEventReportForm.patientSex = res.patientSex
        this.adverseEventReportForm.diseaseEffect = res.diseaseEffect
        this.adverseEventReportForm.mainPerformance = res.mainPerformance
        this.adverseEventReportForm.incidentDate = res.incidentDate
        this.adverseEventReportForm.findKnowDate = res.findKnowDate
        this.adverseEventReportForm.actualUsePlace = res.actualUsePlace
        this.adverseEventReportForm.aftermath = res.aftermath
        this.adverseEventReportForm.deathDate = res.deathDate
        this.adverseEventReportForm.eventStatement = res.eventStatement
        this.adverseEventReportForm.productName = res.productName
        this.adverseEventReportForm.commodityName = res.commodityName
        this.adverseEventReportForm.registrationNo = res.registrationNo
        this.adverseEventReportForm.manufacturingCompanyName = res.manufacturingCompanyName
        this.adverseEventReportForm.manufacturingCompanyAddress = res.manufacturingCompanyAddress
        this.adverseEventReportForm.companyContactPhone = res.companyContactPhone
        this.adverseEventReportForm.spec = res.spec
        this.adverseEventReportForm.productNo = res.productNo
        this.adverseEventReportForm.productBatch = res.productBatch
        this.adverseEventReportForm.operator = res.operator
        this.adverseEventReportForm.validPeriod = res.validPeriod
        this.adverseEventReportForm.productionDate = res.productionDate
        this.adverseEventReportForm.retirementDate = res.retirementDate
        this.adverseEventReportForm.implantDate = res.implantDate
        this.adverseEventReportForm.initialCauseAnalysis = res.initialCauseAnalysis
        this.adverseEventReportForm.handlingSituation = res.handlingSituation
        this.adverseEventReportForm.reportStatus = res.reportStatus
        this.adverseEventReportForm.evaluation1 = res.evaluation1
        this.adverseEventReportForm.evaluation2 = res.evaluation2
        this.adverseEventReportForm.evaluation3 = res.evaluation3
        this.adverseEventReportForm.evaluationConclusion = res.evaluationConclusion
        this.adverseEventReportForm.provincialEvaluationOpinion = res.provincialEvaluationOpinion
        this.adverseEventReportForm.nationalEvaluationOpinion = res.nationalEvaluationOpinion
        this.adverseEventReportForm.reporter = res.reporter
        this.adverseEventReportForm.reporterSignature = res.reporterSignature
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectAdverseEventReportList(this.searchForm).then(res => {
        this.adverseEventReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectAdverseEventReportList(this.searchForm).then(res => {
        this.adverseEventReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectAdverseEventReportList(this.searchForm).then(res => {
        this.adverseEventReportPage = res
      })
    }
  }
}
</script>

<style>
</style>
