import axios from '@/common/axios'
import qs from 'qs'

export function addAdverseEventReport (data) {
  return axios({
    method: 'post',
    url: '/quality/adverse/add',
    data: qs.stringify(data)
  })
}

export function deleteAdverseEventReport (id) {
  return axios({
    method: 'delete',
    url: '/quality/adverse/delete/' + id
  })
}

export function updateAdverseEventReport (data) {
  return axios({
    method: 'put',
    url: '/quality/adverse/update',
    data: qs.stringify(data)
  })
}

export function selectAdverseEventReportInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/adverse/info/' + id
  })
}

export function selectAdverseEventReportList (query) {
  return axios({
    method: 'get',
    url: '/quality/adverse/list',
    params: query
  })
}
